import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {EventFeedComponent} from './components/event-feed/event-feed.component';
import {RecentEventInfoComponent} from './modals/recent-event-info/recent-event-info.component';
import {RecentEventsRoutingModule} from './recent-events-routing.module';
import {RecentEventModalComponent} from './modals/recent-event-modal/recent-event-modal.component';
// tslint:disable-next-line:no-implicit-dependencies
import {GridsterModule} from 'angular-gridster2';
import {MapModule} from '../map/map.module';
import {CommunityModule} from '../community/community.module';
import {OutageModule} from '../outage/outage.module';
import {WidgetModule} from '../widget/widget.module';
import {LayerModule} from '../layer/layer.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [EventFeedComponent, RecentEventInfoComponent, RecentEventModalComponent],
    imports: [
        CommonModule,
        RecentEventsRoutingModule,
        SharedModule,
        GridsterModule,
        MapModule,
        LayerModule,
        CommunityModule,
        OutageModule,
        WidgetModule,
        FontAwesomeModule,
    ],
    exports: [RecentEventInfoComponent, RecentEventModalComponent],
})
export class RecentEventsModule {}
