import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventFeedComponent} from './components/event-feed/event-feed.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full',
    },
    {
        path: 'feed',
        component: EventFeedComponent,
        data: {
            useNewDesign: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RecentEventsRoutingModule {}
